import Sections from '@/components/Sections/Sections'
import { FOUR_OH_FOUR_SLUG } from '@/data'
import { getPage } from '@/data/sanity'

export default function Page404({ sections, infiniteScrollingEnabled, hasFooter }) {
  return (
    <Sections
      pageType="page"
      pageSlug={FOUR_OH_FOUR_SLUG}
      sections={sections}
      hasFooter={hasFooter}
      infiniteScroll={infiniteScrollingEnabled}
    />
  )
}

export const getStaticProps = async context => {
  const data = await getPage(FOUR_OH_FOUR_SLUG, 'page', Boolean(context.preview))

  return {
    props: {
      ...data,
    },
    revalidate: parseInt(process.env.REVALIDATE || '60'),
  }
}
